import React, { useState } from 'react';
import styled from 'styled-components';
import * as prismic from '@prismicio/client';
import { graphql, Link } from 'gatsby';
import { useDebounce } from 'react-use';
import { PrismicRichText } from '@prismicio/react';
import { GATSBY_PRISMIC_NAME, GATSBY_PRISMIC_TOKEN } from '../../constants';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Header from 'components/Header';
import headerPro from 'images/headerPro.svg';
import { Container, Section, Button, Buttons } from 'ui';
import { MdChevronLeft } from 'react-icons/md';
import { ButtonReset } from 'ui';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { asText } from '@prismicio/richtext';

const Card = styled.div`
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: ${({ theme }) => theme.spacing()};
  padding: ${({ theme }) => theme.spacing()};
  padding-right: 80px;

  background-color: ${({ theme }) => theme.card.background};
  display: block;
  color: inherit;
  text-decoration: none;

  h2 {
    margin: 0;
  }
`;

const PlusButton = styled(ButtonReset)`
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.secondary};
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const WrapperIcon = styled.div`
  position: relative;
`;

const Horizontal = styled.div`
  position: absolute;
  background-color: #fff;
  width: 20px;
  height: 4px;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  margin-top: -2px;
`;

const Vertical = styled.div`
  position: absolute;
  background-color: #fff;
  width: 4px;
  height: 20px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: -10px;
`;

const SearchPage = ({ location }) => {
  const [selectedHandicap] = useState(
    location?.state?.selectedHandicap ?? null,
  );
  const [selectedIssue] = useState(location?.state?.selectedIssue);
  const [selectedSpeciality] = useState(location?.state?.selectedSpeciality);
  const [selectedTerm] = useState(location?.state?.selectedTerm);
  const [selectedTermDebounced, setSelectedTermDebounced] = useState(
    location?.state?.selectedTerm,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [results, setResults] = useState([]);
  const [fulfilled, setFulfilled] = useState(false);

  const apiEndpoint = `https://${GATSBY_PRISMIC_NAME}.prismic.io/api/v2`;
  const client = prismic.createClient(apiEndpoint, {
    accessToken: GATSBY_PRISMIC_TOKEN,
  });

  const handleFetchData = async () => {
    setFulfilled(false);
    try {
      const { results, page, total_pages } = await client.get(
        {
          predicates: [
            prismic.predicate.at('document.type', 'pro_article'),

            Boolean(selectedTermDebounced) &&
              prismic.predicate.fulltext('document', selectedTermDebounced),

            Boolean(selectedHandicap) &&
              selectedHandicap.value !== 'all' &&
              prismic.predicate.at(
                'my.pro_article.handicaps.handicap',
                selectedHandicap.value,
              ),

            Boolean(selectedIssue) &&
              selectedIssue.value !== 'all' &&
              prismic.predicate.at(
                'my.pro_article.issues.issue',
                selectedIssue.value,
              ),

            Boolean(selectedSpeciality) &&
              selectedSpeciality.value !== 'all' &&
              prismic.predicate.at(
                'my.pro_article.specialities.speciality',
                selectedSpeciality.value,
              ),
          ].filter(Boolean),
        },
        { pageSize: 10, page: currentPage },
      );
      setResults(results);
      setCurrentPage(page);
      setTotalPage(total_pages);
      setFulfilled(true);
    } catch (err) {
      console.dir(err);
    }
  };

  useDebounce(
    () => {
      setSelectedTermDebounced(selectedTerm);
    },
    500,
    [selectedTerm],
  );

  useDeepCompareEffect(() => {
    handleFetchData();
    // eslint-disable-next-line
  }, [
    {
      selectedHandicap,
      selectedIssue,
      selectedSpeciality,
      selectedTermDebounced,
      currentPage,
    },
  ]);

  return (
    <Layout variant="pro">
      <Seo title="Recherche pour les professionnels de santé" />
      <Header
        title="Accès professionnels de santé"
        icon={headerPro}
        variant="withLogo"
        app="pro"
      />

      <Section>
        <Container>
          <ButtonReset hasMarginBottom style={{ marginBottom: '3rem' }}>
            <Button variant="primary" as={Link} to="/pro">
              <MdChevronLeft />
              Retour
            </Button>
          </ButtonReset>

          {!fulfilled && <div>Chargement</div>}

          {fulfilled && results.length === 0 && <div>Aucun résultat</div>}

          {results.map(({ data, uid }) => (
            <Card
              as={Link}
              to={`/pro/recherche/${uid}`}
              style={{ position: 'relative' }}
            >
              <PrismicRichText field={data?.title} />

              <p>{asText(data?.description)}</p>
              <PlusButton>
                <WrapperIcon>
                  <Horizontal />
                  <Vertical transition={{ ease: 'easeOut', duration: 0.25 }} />
                </WrapperIcon>
              </PlusButton>
            </Card>
          ))}

          {Boolean(totalPage) && (
            <Buttons>
              {currentPage >= 2 && (
                <Button
                  variant="textButton"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  Précédent
                </Button>
              )}
              {currentPage !== totalPage && (
                <Button
                  app="pro"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  Suivant
                </Button>
              )}
            </Buttons>
          )}
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allPrismicHandicap {
      edges {
        node {
          uid
          id
          data {
            name {
              text
              richText
            }
          }
        }
      }
    }
    allPrismicIssue {
      edges {
        node {
          uid
          id
          data {
            name {
              text
              richText
            }
          }
        }
      }
    }
    allPrismicSpecialty {
      edges {
        node {
          uid
          id
          data {
            name {
              text
              richText
            }
          }
        }
      }
    }
  }
`;

export default SearchPage;
